export const TOTAL_DOCUMENT_LIMIT = 20;

export const NATIONAL_COURT_FORM_NAME_MAP = {
  uscis: 'USCIS FORMS',
  bc: 'BRITISH COLUMBIA FORMS',
};

export const COURT_FORM_ANNUAL_PLANS = [
  {
    name: 'USCIS FORMS',
    id: 'FEDERAL_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'CALIFORNIA FORMS',
    id: 'CA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'TEXAS FORMS',
    id: 'TX_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'ILLINOIS FORMS',
    id: 'IL_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NEW YORK FORMS',
    id: 'NY_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'FLORIDA FORMS',
    id: 'FL_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'GEORGIA FORMS',
    id: 'GA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'OHIO FORMS',
    id: 'OH_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'PENNSYLVANIA FORMS',
    id: 'PA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MARYLAND FORMS',
    id: 'MD_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'ARIZONA FORMS',
    id: 'AZ_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MICHIGAN FORMS',
    id: 'MI_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'WASHINGTON FORMS',
    id: 'WA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'WISCONSIN FORMS',
    id: 'WI_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MASSACHUSETTS FORMS',
    id: 'MA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NEW JERSEY FORMS',
    id: 'NJ_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NORTH CAROLINA FORMS',
    id: 'NC_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'COLORADO FORMS',
    id: 'CO_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MINNESOTA FORMS',
    id: 'MN_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'CONNECTICUT FORMS',
    id: 'CT_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NEVADA FORMS',
    id: 'NV_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'IOWA FORMS',
    id: 'IA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'UTAH FORMS',
    id: 'UT_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'RHODE ISLAND FORMS',
    id: 'RI_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'DELAWARE FORMS',
    id: 'DE_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'KENTUCKY FORMS',
    id: 'KY_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NEW MEXICO FORMS',
    id: 'NM_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'INDIANA FORMS',
    id: 'IN_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MISSOURI FORMS',
    id: 'MO_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'OKLAHOMA FORMS',
    id: 'OK_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MAINE FORMS',
    id: 'ME_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'VIRGINIA FORMS',
    id: 'VA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'HAWAII FORMS',
    id: 'HI_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'OREGON FORMS',
    id: 'OR_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'IDAHO FORMS',
    id: 'ID_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'ALABAMA FORMS',
    id: 'AL_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NEBRASKA FORMS',
    id: 'NE_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'SOUTH CAROLINA FORMS',
    id: 'SC_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'ARKANSAS FORMS',
    id: 'AR_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'LOUISIANA FORMS',
    id: 'LA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'WEST VIRGINIA FORMS',
    id: 'WV_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NORTH DAKOTA FORMS',
    id: 'ND_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'SOUTH DAKOTA FORMS',
    id: 'SD_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'TENNESSEE FORMS',
    id: 'TN_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'ALASKA FORMS',
    id: 'AK_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'VERMONT FORMS',
    id: 'VT_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'WYOMING FORMS',
    id: 'WY_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NEW HAMPSHIRE FORMS',
    id: 'NH_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MISSISSIPPI FORMS',
    id: 'MS_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'KANSAS FORMS',
    id: 'KS_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MONTANA FORMS',
    id: 'MT_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'DISTRICT OF COLUMBIA FORMS',
    id: 'DC_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },

  {
    name: 'BRITISH COLUMBIA FORMS',
    id: 'CA_BC_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
].sort((plan1, plan2) => {
  // Sort order is -> USCIS then BC then states alphabetically
  // placeholder until we get a better plan in place for subscription/signup

  // Comparing upper case for consistency and since localeCompare is case sensitive
  const plan1Upper = plan1.name.toString().toUpperCase();
  const plan2Upper = plan2.name.toString().toUpperCase();
  // First handle national forms with USCIS first then BC
  if (plan1Upper === NATIONAL_COURT_FORM_NAME_MAP.uscis) {
    return -1;
  }
  if (plan2Upper === NATIONAL_COURT_FORM_NAME_MAP.uscis) {
    return 1;
  }
  if (plan1Upper === NATIONAL_COURT_FORM_NAME_MAP.bc) {
    return -1;
  }
  if (plan2Upper === NATIONAL_COURT_FORM_NAME_MAP.bc) {
    return 1;
  }
  // Finally localeCompare handles default alphabetic sorting for us
  // (i.e. it handles sorting Arizona before of California)
  return plan1Upper.localeCompare(plan2Upper);
});

export const COURT_FORM_MONTHLY_PLANS = [
  {
    name: 'USCIS FORMS',
    id: 'FEDERAL_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'CALIFORNIA FORMS',
    id: 'CA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'TEXAS FORMS',
    id: 'TX_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'ILLINOIS FORMS',
    id: 'IL_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NEW YORK FORMS',
    id: 'NY_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'FLORIDA FORMS',
    id: 'FL_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'GEORGIA FORMS',
    id: 'GA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'OHIO FORMS',
    id: 'OH_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'PENNSYLVANIA FORMS',
    id: 'PA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MARYLAND FORMS',
    id: 'MD_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'ARIZONA FORMS',
    id: 'AZ_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MICHIGAN FORMS',
    id: 'MI_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'WASHINGTON FORMS',
    id: 'WA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'WISCONSIN FORMS',
    id: 'WI_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MASSACHUSETTS FORMS',
    id: 'MA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NEW JERSEY FORMS',
    id: 'NJ_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NORTH CAROLINA FORMS',
    id: 'NC_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'COLORADO FORMS',
    id: 'CO_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MINNESOTA FORMS',
    id: 'MN_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'CONNECTICUT FORMS',
    id: 'CT_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NEVADA FORMS',
    id: 'NV_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'IOWA FORMS',
    id: 'IA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'UTAH FORMS',
    id: 'UT_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'RHODE ISLAND FORMS',
    id: 'RI_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'DELAWARE FORMS',
    id: 'DE_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'KENTUCKY FORMS',
    id: 'KY_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NEW MEXICO FORMS',
    id: 'NM_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'INDIANA FORMS',
    id: 'IN_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MISSOURI FORMS',
    id: 'MO_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'OKLAHOMA FORMS',
    id: 'OK_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MAINE FORMS',
    id: 'ME_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'VIRGINIA FORMS',
    id: 'VA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'HAWAII FORMS',
    id: 'HI_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'OREGON FORMS',
    id: 'OR_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'IDAHO FORMS',
    id: 'ID_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'ALABAMA FORMS',
    id: 'AL_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NEBRASKA FORMS',
    id: 'NE_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'SOUTH CAROLINA FORMS',
    id: 'SC_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'ARKANSAS FORMS',
    id: 'AR_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'LOUISIANA FORMS',
    id: 'LA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'WEST VIRGINIA FORMS',
    id: 'WV_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NORTH DAKOTA FORMS',
    id: 'ND_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'SOUTH DAKOTA FORMS',
    id: 'SD_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'TENNESSEE FORMS',
    id: 'TN_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'ALASKA FORMS',
    id: 'AK_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'VERMONT FORMS',
    id: 'VT_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'WYOMING FORMS',
    id: 'WY_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NEW HAMPSHIRE FORMS',
    id: 'NH_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MISSISSIPPI FORMS',
    id: 'MS_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'KANSAS FORMS',
    id: 'KS_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MONTANA FORMS',
    id: 'MT_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'DISTRICT OF COLUMBIA FORMS',
    id: 'DC_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },

  {
    name: 'BRITISH COLUMBIA FORMS',
    id: 'CA_BC_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
].sort((plan1, plan2) => {
  // Sort order is -> USCIS then BC then states alphabetically
  // placeholder until we get a better plan in place for subscription/signup

  // Comparing upper case for consistency and since localeCompare is case sensitive
  const plan1Upper = plan1.name.toString().toUpperCase();
  const plan2Upper = plan2.name.toString().toUpperCase();
  // First handle national forms with USCIS first then BC
  if (plan1Upper === NATIONAL_COURT_FORM_NAME_MAP.uscis) {
    return -1;
  }
  if (plan2Upper === NATIONAL_COURT_FORM_NAME_MAP.uscis) {
    return 1;
  }
  if (plan1Upper === NATIONAL_COURT_FORM_NAME_MAP.bc) {
    return -1;
  }
  if (plan2Upper === NATIONAL_COURT_FORM_NAME_MAP.bc) {
    return 1;
  }
  // Finally localeCompare handles default alphabetic sorting for us
  // (i.e. it handles sorting Arizona before of California)
  return plan1Upper.localeCompare(plan2Upper);
});

export const COURT_FORM_PLANS = {
  monthly: COURT_FORM_MONTHLY_PLANS,
  annual: COURT_FORM_ANNUAL_PLANS,
};

export const DOCUMENT_AUTOMATION_PLANS = {
  monthly: [
    {
      name: 'WORD DOCUMENT AUTOMATION',
      id: 'WORD_DOCUMENT_AUTOMATION_MONTHLY',
      interval: 'mo',
      recurring: true,
      price: 80,
    },
  ],
  annual: [
    {
      name: 'WORD DOCUMENT AUTOMATION',
      id: 'WORD_DOCUMENT_AUTOMATION_YEARLY',
      interval: 'year',
      recurring: true,
      price: 800,
      discount: 160,
    },
  ],
};

export const SEATS = [
  {
    name: 'seats',
    id: 'SEATS_MONTHLY',
    interval: 'mo',
  },
  {
    name: 'seats',
    id: 'SEATS_YEARLY',
    interval: 'year',
  },
];

export const CLIO_ERROR_STATE = {
  COURT_FORMS_NOT_ENABLED: 'court forms not enabled',
  GENERIC_SYNC: 'something went wrong with your sync',
  MATTER_SYNC_NOT_ENABLED: 'matter sync not enabled',
  SYNC_NOT_ENABLED: 'sync not enabled',
  SYNC_IN_QUEUE_OR_IN_PROGRESS: 'Sync is already queued or processing',
};

// All mandatory fields in Contact form
export const CONTACT_REQUIRED_FIELDS = ['lastName'];

export const PERSON_CONTACT_SPECIFIC_FIELDS = [
  'lastName',
  'firstName',
  'fullName',
  'middleName',
];

export const ORGANIZATION_CONTACT_SPECIFIC_FIELDS = [
  'lastName', // As of now this getting mapped into Organization Name.
];

export const FORM_FIELD_REQUIRED_ERROR_MESSAGE = 'This field is required';
export const FORM_EMAIL_FIELD_ERROR_MESSAGE = 'Invalid email.';

export const FORM_FIELD_ERROR_CODE_MAPPING = {
  FORM_FIELD_REQUIRED_ERROR_MESSAGE: 'E101',
  FORM_EMAIL_FIELD_ERROR_MESSAGE: 'E102',
};

export const CONTACT_EDIT_FORM_EXCLUDED_FIELDS = [
  'contactType',
  'contactSource',
];
