import React from 'react';

type DueTodayProps = {
  total: number;
  discount: number;
  discount_percent: number;
};

const TotalLineItem = ({ pricing }: { pricing: DueTodayProps }) => {
  return (
    <div className="flex flex-row justify-between text-xs">
      <span>Total</span>
      <span className="font-semibold">
        ${pricing.total / 100 + pricing.discount / 100}
      </span>
    </div>
  );
};

const DueTodayLineItem = ({ pricing }: { pricing: DueTodayProps }) => {
  return (
    <div className="flex flex-row justify-between text-xs">
      <span>Due today</span>
      <span className="font-semibold">${pricing.total / 100}</span>
    </div>
  );
};

const DiscountLineItem = ({ pricing }: { pricing: DueTodayProps }) => {
  if (!pricing.discount) {
    return <></>;
  }
  return (
    <div className="flex flex-row justify-between text-xs mb-4">
      <span>Discount ({pricing.discount_percent}%)</span>
      <span className="font-semibold text-emerald-600">
        -${pricing.discount / 100}
      </span>
    </div>
  );
};

const SelectPlanText = () => {
  return (
    <div className="flex flex-row justify-between text-xs mb-4">
      <span>Select a plan first to see total due </span>
    </div>
  );
};

export const DueToday = ({
  pricing,
  hasSelectedProducts,
}: {
  pricing: DueTodayProps;
  hasSelectedProducts: boolean;
}) => {
  return (
    <div>
      <div className="text-base font-medium mb-2 mt-6">Due</div>
      {hasSelectedProducts ? (
        <>
          <TotalLineItem pricing={pricing} />
          <DiscountLineItem pricing={pricing} />
          <DueTodayLineItem pricing={pricing} />
        </>
      ) : (
        <SelectPlanText />
      )}
    </div>
  );
};
