import React from 'react';
import { css } from 'aphrodite';
import { CardTitle, CardBreak } from '~/src/components/Card';
import Button from '~/src/components/Button';
import { Label } from '~/src/components/Inputs';
import Spinner from '~/src/components/Spinner';
import useUser from '~/src/hooks/useUser';

import styles from '../styles';
import { ChoosePlanForm } from './ChoosePlanForm';
import { UpdatePaymentMethodButton } from '../UpdatePaymentMethodButton';
import { RenewSubscriptionButton } from '../RenewSubscriptionButton';
import { useGetProductPricing } from '~/src/entities/subscription/hooks/useGetProductPricing';
import { LoadingOverlay } from '~/src/components/PageLayout';
import { Error } from '~/src/components/Type';

export const SubscriptionDetails = ({
  subscription,
  onUpdateSeats,
  nextInvoice,
  cycle,
  handlePlanChange,
  courtFormPlans,
  documentAutomationPlans,
  pendingRequest,
}) => {
  const { quantity } = subscription;
  const seatLabel = quantity > 1 ? 'seats' : 'seat';
  const endDate = subscription.nextInvoiceEndFormatted();

  const { ccId } = subscription;

  const canceled = subscription.isCanceled();
  const pastDue = subscription.isStatusPastDue();
  const isValidSubscription = !canceled && !pastDue;

  const productPricing = useGetProductPricing();

  const handleUpdateSeats = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (onUpdateSeats) {
      onUpdateSeats();
    }
  };

  const org = useUser().currentOrganization;

  let renewSubscriptionBtnComponent;
  if (pastDue) {
    renewSubscriptionBtnComponent = (
      <RenewSubscriptionButton
        message={`Your subscription was past due on ${endDate}`}
      />
    );
  } else if (canceled) {
    renewSubscriptionBtnComponent = (
      <RenewSubscriptionButton
        message={
          org.subscription.remainingDaysFromCanceledDate()
            ? `Your subscription was canceled on ${endDate}`
            : `Your subscription is set to cancel on ${endDate}`
        }
      />
    );
  }

  if (productPricing.error) {
    return <Error>{'Error loading Product Pricing'}</Error>;
  }
  if (productPricing.isLoading) {
    return (
      <LoadingOverlay title={'Loading Product Pricing...'} relative={true} />
    );
  }

  return (
    <div className="relative">
      <CardTitle bold>Subscription</CardTitle>

      <div className={css(!canceled && styles.settingsRow)}>
        <Label className={css(styles.settingsLabel)} label={'Plan'}>
          {quantity} {seatLabel}
        </Label>
      </div>

      {isValidSubscription && (
        <div className={css(styles.settingsRow)}>
          <Button primary onClick={handleUpdateSeats}>
            Update seats
          </Button>
        </div>
      )}

      {renewSubscriptionBtnComponent}

      {isValidSubscription && (
        <div>
          <ChoosePlanForm
            cycle={cycle}
            onChange={handlePlanChange}
            courtFormPlans={[...courtFormPlans]}
            documentAutomationPlans={documentAutomationPlans}
            productPricingData={productPricing.data ?? {}}
          />
          <div className="h-10" />
          <div className={css(styles.settingsRow)}>
            <CardBreak />
          </div>
        </div>
      )}

      {isValidSubscription && (
        <div className={css(styles.settingsRow)}>
          <Label className={css(styles.settingsLabel)} label={'Next invoice'}>
            ${nextInvoice} on {endDate}
          </Label>
        </div>
      )}
      <div className={css(styles.settingsRow)}>
        <Label className={css(styles.settingsLabel)} label={'Payment method'}>
          Card ending in {ccId}
        </Label>
        <UpdatePaymentMethodButton />
      </div>

      {pendingRequest && (
        <div className="absolute flex items-center justify-center -inset-8 bg-opacity-50 bg-gray-100">
          <Spinner />
        </div>
      )}
    </div>
  );
};
