import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import theme from '~/src/theme';
import Card from '../Card';
import Checkbox from '../Checkbox';
import type { ProductPricing } from '~/src/entities/subscription/hooks/useGetProductPricing';

type Plan = {
  interval: string;
  name: string;
  price: number;
  id: string;
};

type ConfigurePlanModalProps = {
  handleCancel: () => void;
  handleChange: ({
    index,
    selected,
  }: {
    index: number;
    selected: boolean[];
  }) => void;
  handleUpdate: (selected: boolean[]) => boolean[];
  open: boolean;
  index: number;
  defaultValue: boolean[];
  plans: Plan[] | ((selected: boolean[]) => Plan[]);
  productPricingData: ProductPricing;
};

const ConfigurePlanModal = ({
  open,
  plans,
  index,
  defaultValue,
  handleCancel,
  handleChange,
  handleUpdate,
  productPricingData,
}: ConfigurePlanModalProps) => {
  const [selected, setSelected] = useState(defaultValue);

  const plansToRender = typeof plans === 'function' ? plans(selected) : plans;
  plansToRender.forEach((plan) => {
    const productPrice = productPricingData[plan.id];
    plan.price = (productPrice || 0) / 100;
  });

  const title = index
    ? 'Add document automation to your plan'
    : 'Choose court form packages';

  const onConfirm = () => {
    handleChange({ index, selected });
  };

  const toggleCheck = (index: number) => {
    const nextSelected = selected.map((s, i) => (index === i ? !s : s));
    setSelected(handleUpdate ? handleUpdate(nextSelected) : nextSelected);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      scroll={'paper'}
      PaperProps={{
        sx: {
          width: '100%',
          maxHeight: '560px!important',
          minWidth: '560px!important',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>
        {plansToRender &&
          plansToRender.map(
            ({ interval, name, price, ...checkboxProps }, key) => (
              <div className="w-full mb-2" key={key}>
                <Card dynamic>
                  <div className="flex flex-row items-start">
                    <Checkbox
                      {...checkboxProps}
                      id={key}
                      disabled={false}
                      checked={selected[key]}
                      color={theme.colors.darkBlue}
                      size={3}
                      onClick={() => toggleCheck(key)}
                    />
                    <div className="flex flex-row items-center justify-between w-full ml-2">
                      <div className="flex flex-col">
                        <div className="font-medium text-sm mb-1">{name}</div>
                      </div>
                      <div className="font-normal text-xs">
                        {`$${price}/ ${interval}`}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            ),
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={() => onConfirm()} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigurePlanModal;
