import { useQuery } from 'react-query';
import { useCurrentOrgFprint } from '../../user';
import { composeQueryOpts, fetchAndParse } from '../../utils';
import { getProductPricing } from '../urls';

export interface ProductPricing {
  [key: string]: number;
}

export const useGetProductPricing = () => {
  const orgFprint = useCurrentOrgFprint();
  return useQuery(
    composeQueryOpts({
      queryKey: ['product-pricing'],
      queryFn: () =>
        fetchAndParse<ProductPricing>(getProductPricing(orgFprint)),
    }),
  );
};
