import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';
import { slideInRight, slideOutRight } from 'react-animations';

export const SLIDE_IN_ANIMATION_DURATION = 500;
export const NAVIGATION_WIDTH = 160;

const showHide = {
  '0%': {
    top: '0%',
    left: '0%',
  },
  '100%': {
    top: '100%',
    left: '100%',
  },
};

export default StyleSheet.create({
  /* Layout */
  column: {
    // width: '100%',
    flexGrow: 1,
    boxSizing: 'border-box',
  },
  columnSmall: {
    padding: theme.unit * 2,
  },
  row: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
  },
  container: {
    display: 'flex',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  mainColumn: {
    display: 'flex',
    flexFlow: 'column',
    maxHeight: '100vh',
    position: 'relative',
    flexGrow: 1,
  },

  mainColumnBody: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column',
    overflowY: 'auto',
    maxHeight: '100%',
    padding: theme.unit * 2,
    position: 'relative',
    boxSizing: 'border-box',
    zIndex: 0,
  },
  mainColumnBodyWithoutPadding: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column',
    overflowY: 'auto',
    maxHeight: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    zIndex: 0,
  },
  mainColumnBodyWithoutHorizontalPadding: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column',
    overflowY: 'scroll',
    maxHeight: '100%',
    paddingTop: theme.unit * 2,
    paddingBottom: theme.unit * 2,
    position: 'relative',
    boxSizing: 'border-box',
    zIndex: 0,
  },
  questionnaireBuilder: {
    flexGrow: 1,
    padding: 0,
    zIndex: 0,
  },
  mainColumnNoPadding: {
    padding: 0,
  },
  mainColumnBodyContainer: {
    flexGrow: 1,
    display: 'flex',
    minHeight: 0,
  },
  sidebar: {
    display: 'flex',
    minWidth: NAVIGATION_WIDTH,
    height: '100vh',
    background: theme.colors.charcoal,
    width: NAVIGATION_WIDTH,
    position: 'relative',
    overflow: 'hidden',
  },
  subsidebar: {
    display: 'block',
    overflowY: 'scroll',
    maxHeight: '100%',
    background: theme.colors.white,
    width: theme.unit * 31,
    borderRight: theme.border,
  },
  toolsSeperator: {
    color: '#9CA3AF',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '16px',
    padding: `${theme.unit * 2}px ${theme.unit * 3}px`,
  },
  navigationLogo: {
    padding: `${theme.unit * 3}px 7px ${theme.unit * 3}px 25px`,
    ':hover': {
      cursor: 'pointer',
      background: theme.colors.charcoal,
    },
  },
  navigationItem: {
    display: 'flex',
    color: theme.colors.white,
    padding: '16px 24px',
    fontSize: theme.fonts.size.small,
    transition: 'background 0.2s ease',
    ':hover': {
      cursor: 'pointer',
      background: '#3D454D',
    },
  },
  navigationItemActive: {
    background: '#3D454D',
  },
  navigation: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    height: '100vh',
    width: NAVIGATION_WIDTH,
  },
  navigationItemLogo: {
    display: 'flex',
  },
  navigationItemLabel: {
    marginLeft: '10px',
    fontSize: '12px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.surface,
    padding: `${theme.unit * 2}px ${theme.unit * 3}px`,
    fontSize: theme.fonts.size.regular,
    borderBottom: theme.border,
  },
  headerTitle: {
    fontWeight: 'normal',
  },
  headerDropdown: {
    padding: theme.unit * 2,
    borderBottom: theme.border,
    background: '#F9F9FA',
    marginLeft: -theme.unit * 2,
    marginRight: -theme.unit * 2,
    marginTop: -theme.unit * 2,
    marginBottom: theme.unit * 2,
  },
  headerDropdownOrganizationLabel: {
    marginTop: theme.unit,
    fontSize: theme.fonts.size.regular,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  headerDropdownOrganizationEmail: {
    opacity: 0.6,
    marginTop: theme.unit,
    fontSize: theme.fonts.size.small,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: theme.surface,
    padding: `${theme.unit * 2}px ${theme.unit * 3}px`,
    fontSize: theme.fonts.size.regular,
    borderTop: theme.border,
    zIndex: 1,
    boxShadow: `1px 1px 3px 0px rgba(222, 222, 223, 0.25), -1px - 1px 3px 0px rgba(222, 222, 223, 0.25)`,
  },
  slideInOverlayContainerHide: {
    top: '100%',
    left: '100%',
  },
  slideInOverlayContainerHideAnimate: {
    animationName: showHide,
    animationDuration: 0,
    animationDelay: `${SLIDE_IN_ANIMATION_DURATION}ms`,
    animationFillMode: 'forwards',
  },
  slideInOverlayContainer: {
    display: 'block',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    zIndex: 10,
  },
  slideInOverlayClickCatcher: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    bottom: 0,
    background: 'rgba(48,53,58,0.1)',
    transition: 'background 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  slideInOverlayClickCatcherShow: {
    background: 'rgba(48,53,58,0.62)',
    ':hover': {
      cursor: 'pointer',
    },
  },
  slideInOverlayPanel: {
    position: 'absolute',
    width: 400,
    top: 0,
    bottom: 0,
    right: 0,
    background: theme.surface,
    boxShadow: '0 7px 24px 0 rgba(28,34,45,0.24)',
    animationName: slideOutRight,
    animationDuration: `${SLIDE_IN_ANIMATION_DURATION}ms`,
    animationFillMode: 'forwards',
  },
  slideInOverlayPanelLarge: {
    width: 800,
  },
  slideInOverlayPanelShow: {
    animationName: slideInRight,
    animationDuration: `${SLIDE_IN_ANIMATION_DURATION}ms`,
    animationFillMode: 'backwards',
  },
  slideInOverlayExit: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: theme.unit,
    left: -theme.unit * 6,
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.13)',
    background: theme.surface,
    width: theme.unit * 4,
    height: theme.unit * 4,
    borderRadius: theme.unit * 2,
    ':hover': {
      cursor: 'pointer',
    },
  },

  /* Loading Overlay */
  loadingOverlayContainer: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    width: '100%',
    // height: "50%",
    overflow: 'auto',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingOverlayContainerRelative: {
    position: 'absolute',
  },
  loadingOverlayTitle: {
    display: 'block',
    marginTop: theme.unit * 2,
    textAlign: 'center',
    fontSize: theme.fonts.size.regular,
    color: theme.colors.charcoal,
  },
  loadingOverlaySubtitle: {
    marginTop: theme.unit,
    textAlign: 'center',
    fontSize: theme.fonts.size.small,
    color: theme.colors.chrome,
  },
  helpMenuTooltip: {
    boxShadow: '0 4px 8px rgba(0, 0, 0, .1)',
    border: '1px solid #dededf',
  },
});
