import React from 'react';
import { css } from 'aphrodite';
import Button from '~/src/components/Button';
import modalStyles from '~/src/components/Modal/styles';
import { DueToday } from '../DueToday';
import { CouponInput } from './CouponInput';
import { SeatsItem } from './SeatsItem';
import { PlanItem } from './PlanItem';
import { getFormattedProductsFromInvoice } from '~/src/entities/subscription/utils';
import BundlePlanItem from './BundlePlanItem';

export const SummaryForm = ({
  showNextButton,
  totalPrice,
  couponSubmitting,
  onCouponChange,
  onCouponSubmit,
  onConfirm,
  courtFormPlans,
  documentAutomationPlans,
  seats,
  cycle,
  couponError,
  coupon,
  draftInvoice,
}) => {
  const { seatsProduct, courtFormsProducts, docAutoProduct, bundleProduct } =
    getFormattedProductsFromInvoice(draftInvoice);
  const hasSelectedProducts =
    documentAutomationPlans.length > 0 || courtFormPlans.length > 0;

  return (
    <div>
      <h1 className={css(modalStyles.modalTitle)}>Summary</h1>
      <div className="text-base font-medium mb-2">Recurring charges</div>
      <SeatsItem seats={seats} cycle={cycle} seatsProduct={seatsProduct} />
      {bundleProduct && <BundlePlanItem bundle={bundleProduct} />}
      {Object.values(courtFormsProducts).map((product) => {
        return (
          <PlanItem
            name={product.productLabel}
            price={product.subtotal / 100}
            cycle={product.billingCycle}
            key={product.plan_id}
          />
        );
      })}
      {docAutoProduct && (
        <PlanItem
          name={docAutoProduct.productLabel}
          price={docAutoProduct.subtotal / 100}
          cycle={docAutoProduct.billingCycle}
          key={docAutoProduct.plan_id}
        />
      )}
      <CouponInput
        coupon={coupon}
        error={couponError}
        submitting={couponSubmitting}
        onChange={onCouponChange}
        onSubmit={() => onCouponSubmit(coupon)}
      />
      {totalPrice ? (
        <DueToday
          pricing={totalPrice}
          hasSelectedProducts={hasSelectedProducts}
        />
      ) : null}
      {showNextButton && (
        <div className={css(modalStyles.modalActions)}>
          <Button
            cssStyle={modalStyles.buttonAction}
            disabled={!hasSelectedProducts}
            style={{ width: '100%' }}
            onClick={onConfirm}
            primary
          >
            Add payment method
          </Button>
        </div>
      )}
    </div>
  );
};
