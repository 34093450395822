import React, { useState } from 'react';
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

import ConfigurePlanModal from '../Modal/ConfigurePlan';
import { useGetProductPricing } from '~/src/entities/subscription/hooks/useGetProductPricing';
/* Higher Order Components */

/* Configurations */

import Label from './Label';
import Card from '../Card';
import Button from '../Button';
import { Error } from '~/src/components/Type';

const PlanChoiceInput = (props) => {
  const { label, hint, onChange, options, required } = props;

  const productPricing = useGetProductPricing();
  const [planModalIndex, setPlanModalIndex] = useState(null);

  const isPlanSelectModalOpen = planModalIndex !== null;

  if (productPricing.error) {
    return (
      <Error cssStyle={undefined}>{'Error loading Product Pricing'}</Error>
    );
  }

  const content = options.map((field, index) => {
    const { label, plans, defaultValue } = field;
    return (
      <div className="w-full mb-2" key={index}>
        <Card key={index} dynamic>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col">
              <div className="font-medium text-sm mr-4">{label}</div>
            </div>

            {plans.length > 0 && (
              <Button onClick={() => setPlanModalIndex(index)} primary>
                {defaultValue.includes(true) ? 'Edit' : 'Add'}
              </Button>
            )}
          </div>
        </Card>
      </div>
    );
  });

  const handleChange = (data) => {
    onChange(data);
    setPlanModalIndex(null);
  };

  return (
    <>
      <Label label={label} required={required} hint={hint} className="mb-5">
        {content}
      </Label>
      {options?.[planModalIndex] && (
        <ConfigurePlanModal
          open={isPlanSelectModalOpen}
          index={planModalIndex}
          handleCancel={() => setPlanModalIndex(null)}
          handleChange={handleChange}
          productPricingData={productPricing.data || {}}
          {...options?.[planModalIndex]}
        />
      )}
    </>
  );
};

export default WithLayoutProps(PlanChoiceInput);
