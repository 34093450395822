import React from 'react';
import { css } from 'aphrodite';

import { Form, renderFormField } from '~/src/components/Forms';
import modalStyles from '~/src/components/Modal/styles';
import {
  COURT_FORM_PLANS,
  DOCUMENT_AUTOMATION_PLANS,
} from '~/src/utils/constants';

export const ChoosePlanForm = ({
  onChange,
  cycle,
  courtFormPlans,
  documentAutomationPlans,
  productPricingData,
}) => {
  const allCourtFormPlans = COURT_FORM_PLANS[cycle];
  const allDocAutoPlans = [DOCUMENT_AUTOMATION_PLANS[cycle][0]];

  allCourtFormPlans.forEach((plan) => {
    plan.price = productPricingData[plan.id] / 100;
  });
  allDocAutoPlans.forEach((plan) => {
    plan.price = productPricingData[plan.id] / 100;
  });

  const fields = [
    {
      id: 'plan',
      label: '',
      type: 'planChoice',
      options: [
        {
          label: 'Fillable court forms',
          plans: allCourtFormPlans,
          defaultValue: courtFormPlans,
        },
        {
          label: 'Word document automation',
          plans: allDocAutoPlans,
          defaultValue: [documentAutomationPlans],
        },
      ],
      validation: () => true,
    },
    {
      id: 'cycle',
      label: 'Billing cycle',
      type: 'multipleChoice',
      defaultValue: cycle,
      value: cycle,
      options: [
        { label: 'Monthly', value: 'monthly' },
        { label: 'Annual', value: 'annual' },
      ],
      validation: () => true,
    },
  ];

  const renderFields = fields.map((field) => renderFormField(field));

  return (
    <div className={css(modalStyles.modalContainer)}>
      <div className={css(modalStyles.modalBody)}>
        <Form fields={renderFields} onChange={onChange} />
      </div>
    </div>
  );
};
