import { types, flow, getRoot } from 'mobx-state-tree';
import { location } from '~/src/utils/window';
import _ from 'lodash';

import membershipService from '~/src/services/membership';
import cookies from '~/src/utils/cookies';

import { history } from '../../utils/history';

import { ProfileStore } from './profileStore';

import { OrganizationStore } from './organizationStore';
import { PENDING_STATE, SUCCESS_STATE, ERROR_STATE } from './states';

export const UserStore = types
  .model({
    state: types.optional(types.string, PENDING_STATE),
    profile: types.maybeNull(ProfileStore),
    lastOrgId: types.maybeNull(types.integer),
    currentOrganization: types.maybeNull(types.reference(OrganizationStore)),
    organizations: types.optional(types.array(OrganizationStore), []),
  })
  .actions((self) => {
    const verifyTwoFactorAuthSmsCode = flow(function* verifyTwoFactorSMS(
      phone,
      code,
    ) {
      try {
        const res = yield membershipService.verifyTwoFactorAuthSmsCode(
          code,
          phone,
        );
        return res.ok;
      } catch (error) {
        console.error('Failed to verify two factor auth', error);
        return false;
      }
    });

    const sendTwoFactorAuthSmsCode = flow(function* sendTwoFactorAuthSmsCode(
      phoneNumber,
    ) {
      try {
        const res = yield membershipService.sendTwoFactorAuthSmsCode(
          phoneNumber,
        );
        return res.ok || true;
      } catch (error) {
        console.error('Failed to send two factor auth sms code', error);
        return false;
      }
    });

    const disableTwoFactorAuth = flow(function* disableTwoFactorAuth() {
      try {
        const res = yield membershipService.disableTwoFactorAuth();
        self.profile.setSsoEnabled(false);
        return res.ok;
      } catch (error) {
        console.error('Failed to disable two factor auth', error);
        return false;
      }
    });

    const enableTwoFactorAuth = flow(function* enableTwoFactorAuth(type) {
      const twoFactorAuthMethod = type === 'phone' ? 'phone' : 'email';

      try {
        yield membershipService.enableTwoFactorAuth();
        yield membershipService.toggleTwoFactorAuthMethod(twoFactorAuthMethod);

        self.profile.setSsoEnabled(true);
        self.profile.setSsoMethod(twoFactorAuthMethod === 'phone' ? 2 : 1);
        return true;
      } catch (error) {
        self.profile.setSsoEnabled(false);
        console.error('Failed to enable two factor auth', error);
        return false;
      }
    });

    const changePassword = flow(function* (password, newPassword) {
      try {
        const res = yield membershipService.changePassword(
          password,
          newPassword,
        );

        return res.ok;
      } catch (error) {
        console.error('Failed to update password', error);
        return false;
      }
    });

    const switchOrganization = (orgFprint) => {
      cookies.setOrgFprint(orgFprint);
      const nextOrg = _.find(self.organizations, ['fprint', orgFprint]);
      self.currentOrganization = nextOrg.identifier;
      location.reload(true);
    };

    const setDefaultOrg = (orgId) => {
      self.organizations.forEach((org) => {
        org.isDefault = org.id === orgId;
      });
    };

    const fetchMemberships = flow(function* fetchMemberships() {
      self.state = PENDING_STATE;

      try {
        const orgFprint = cookies.getOrgFprint();
        const res = yield membershipService.getMemberships(orgFprint);
        self.profile = res.profile;
        self.organizations = res.organizations;
        self.currentOrganization = res.currentOrganization;
        cookies.setOrgFprint(self.currentOrganization?.fprint);

        if (
          typeof self.currentOrganization.clioIntegrationEnabled !==
            'undefined' &&
          typeof self.currentOrganization.clioLastSync !== 'undefined'
        ) {
          const root = getRoot(self);
          root.clio.setAuthenticated(
            self.currentOrganization.clioIntegrationEnabled,
          );
          root.clio.setLastSynced(self.currentOrganization.clioLastSync);
        }

        yield self.currentOrganization.fetchSubscription();
        res.coupon = yield self.currentOrganization.fetchCoupons();
        if (self.currentOrganization.subscription) {
          res.subscription = self.currentOrganization.subscription.toJSON();
        }
        self.currentOrganization.fetchInvoices();
        self.currentOrganization.fetchReceipts();
        yield self.currentOrganization.fetchMembers();

        // If a user is not and admin and has a subscription ID then them must be an invited member of a team therefore
        // do not send them to the welcome form
        const path =
          (history.location &&
            history.location.pathname &&
            history.location.pathname.split('/').join('')) ||
          '';

        if (self.currentOrganization.subscriptionId) {
          if (path === 'welcome' || path === 'subscribe') {
            history.replace('/library');
          }
        }

        self.state = SUCCESS_STATE;
        return res;
      } catch (error) {
        console.error('Failed fetching membership; logging out', error);
        self.state = ERROR_STATE;
        return false;
      }
    });

    const initialize = flow(function* initialize() {
      const res = yield self.fetchMemberships();
      return res;
    });

    return {
      switchOrganization,
      fetchMemberships,
      setDefaultOrg,
      changePassword,
      enableTwoFactorAuth,
      disableTwoFactorAuth,
      sendTwoFactorAuthSmsCode,
      verifyTwoFactorAuthSmsCode,
      initialize,
    };
  })
  .views((self) => {
    const getCurrentOrgFprint = () => self.currentOrganization.fprint;

    const getCurrentOrgId = () => self.currentOrganization.id;

    const getOrganizationFormOptionList = () => {
      const options = [];

      self.organizations.forEach((org) => {
        options.push({ label: org.organizationName, value: `${org.fprint}` });
      });

      return options;
    };

    return {
      getOrganizationFormOptionList,
      getCurrentOrgFprint,
      getCurrentOrgId,
    };
  });
