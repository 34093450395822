import React, { FC } from 'react';
import { css } from 'aphrodite';

import theme from '~/src/theme';
import styles from './styles';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  disabled?: boolean;
  primary?: boolean;
  success?: boolean;
  alert?: boolean;
  secondaryAlert?: boolean;
  discrete?: boolean;
  cssStyle?: any;
  // TODO: Add type definition
  Icon?: any;
  analyticsname?: string;
}

const Button: FC<ButtonProps> = ({
  children,
  cssStyle,
  className = '',
  disabled = false,
  primary = false,
  success = false,
  alert = false,
  secondaryAlert = false,
  discrete = false,
  Icon,
  onClick,
  ...customProps
}) => {
  const iconButton = Icon && !children;
  const iconSize = iconButton ? theme.fonts.size.large : theme.fonts.size.small;

  return (
    <button
      onClick={(!disabled && onClick) || undefined}
      {...customProps}
      disabled={disabled}
      className={`${css(
        styles.button,
        primary && styles.buttonPrimary,
        success && styles.buttonSuccess,
        disabled && styles.buttonDisabled,
        discrete && styles.buttonDiscrete,
        alert && styles.buttonAlert,
        secondaryAlert && styles.buttonSecondaryAlert,
        iconButton && styles.buttonIconButton,
        cssStyle,
      )} ${className}`}
    >
      {Icon && (
        <i
          className={css(
            styles.buttonIcon,
            iconButton && styles.buttonIconCenter,
          )}
        >
          <Icon size={iconSize} />
        </i>
      )}
      {children}
    </button>
  );
};

export default Button;
