import { APIErrorNotOk } from '~/src/utils/error';
import { getAuthTokenUrl } from './urls';
import {
  type QuestionnaireSubmission,
  type Document,
  type QuestionSubmissionType,
  getQuestionSubmissionResponse,
  getQuestionVersionFromQuestionSubmission,
  getQuestionSubmissionSelectedOptions,
  Field,
  FieldGroup,
  MultipleChoiceOption,
  QuestionVersion,
  SectionVersion,
} from '@clio/questionnaire-builder';
import moment from 'moment';

const dateFormat = 'MM/DD/YYYY';

export const getClioQuestionnaireAuthToken = async (orgFprint: string) => {
  const response = await fetch(getAuthTokenUrl(orgFprint), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new APIErrorNotOk(response);
  }

  const json = await response.json();

  return json.questionnaire_auth_token;
};

export const questionnaireAuthorizationHeaders = (token: string) => ({
  questionnaire_auth_token: token,
});

// return dict of the form { Attribute.id => [ attribute options ] } for
// multiple choice and single select attributes
const getAttributeTagToOptionsMap = (documents: Document[]) => {
  const optionsMap: Record<string, MultipleChoiceOption[]> = {};

  documents.forEach((document: Document) => {
    Object.values(document.field_groups).forEach((fieldGroup: FieldGroup) => {
      Object.values(fieldGroup.fields).forEach((field: Field) => {
        if (
          field.type === 'QuestionMultipleChoiceSingle' ||
          field.type === 'QuestionMultipleChoiceMulti'
        ) {
          const tagStr =
            fieldGroup.normalized_field_group + '.' + field.normalized_field;
          optionsMap[tagStr] = field.multiple_choice_options;
          optionsMap[field.field_group_field_id] =
            field.multiple_choice_options;
        }
      });
    });
  });

  return optionsMap;
};

const getExternalFieldIdToNormalizedFieldDict = (
  documents: Document[],
  canUseNormalizedFieldsAsKeys = false,
): Record<string, string> => {
  const map: Record<string, string> = {};

  documents.forEach((document: Document) => {
    Object.values(document.field_groups).forEach((fieldGroup: FieldGroup) => {
      Object.values(fieldGroup.fields).forEach((field: Field) => {
        let normalized_str =
          fieldGroup.normalized_field_group + '.' + field.normalized_field;
        map[field.field_group_field_id] = normalized_str;

        if (canUseNormalizedFieldsAsKeys) {
          // add the normalized field group and field as a key because the external
          // field identifier may already be normalized
          map[normalized_str] = normalized_str;
        }
      });
    });
  });

  return map;
};

const convertToArrayIfPossible = (element: string) => {
  try {
    const newArrayObject = JSON.parse(element);

    if (Array.isArray(newArrayObject)) {
      return newArrayObject;
    }

    return [];
  } catch (error) {
    return element.split(',').map((item: string) => item.trim());
  }
};

export const getQuestionnaireQuestionArray = (
  questionnaireSubmission: QuestionnaireSubmission,
) => {
  const questionnaireQuestionArray: {
    [key: string]: any;
  }[] = [];

  const questionSubmissions =
    questionnaireSubmission.section_submissions_attributes.flatMap(
      (sectionSubmission) => sectionSubmission.question_submissions_attributes,
    );

  questionSubmissions.forEach((questionSubmission) => {
    const questionVersion = getQuestionVersionFromQuestionSubmission(
      questionnaireSubmission.questionnaire_version,
      questionSubmission,
    );

    const questionSubmissionType: QuestionSubmissionType =
      questionSubmission.question_submission_type;

    let responseLabel: string | null = '';
    if (questionVersion !== undefined) {
      switch (questionSubmissionType) {
        case 'QuestionSubmissionShort':
        case 'QuestionSubmissionLong': {
          responseLabel = questionSubmission.response_text;
          break;
        }
        case 'QuestionSubmissionDate': {
          responseLabel = questionSubmission.response_date;
          break;
        }
        case 'QuestionSubmissionMultipleChoiceSingle': {
          const selectedOptions = getQuestionSubmissionSelectedOptions(
            questionVersion,
            questionSubmission,
          );
          responseLabel = selectedOptions[0]!.label;
          break;
        }
        case 'QuestionSubmissionMultipleChoiceMulti': {
          const selectedOptions = getQuestionSubmissionSelectedOptions(
            questionVersion,
            questionSubmission,
          );
          const optionLabelArray = selectedOptions.map((o) => o.label);
          responseLabel = JSON.stringify(optionLabelArray);
          break;
        }
      }
    }

    questionnaireQuestionArray.push({
      question_version_id: questionVersion!.id,
      question_id: questionVersion!.question_id,
      question: questionVersion!.title,
      response: responseLabel,
      questionSubmissionType,
      mapping_fields: questionVersion!.question_mapping_versions,
    });
  });

  return questionnaireQuestionArray;
};

// derive a map of tag -> response from the questionnaire submission
export const getNormalizedField2ResponseMap = (
  questionnaireSubmission: QuestionnaireSubmission,
  documents: Document[],
  canUseNormalizedFieldsAsKeys = false,
) => {
  const responseDict: { [key: string]: string } = {};

  const questionSubmissions =
    questionnaireSubmission.section_submissions_attributes.flatMap(
      (sectionSubmission) => sectionSubmission.question_submissions_attributes,
    );

  const attributeTagToOptionsMap = getAttributeTagToOptionsMap(documents);

  const externalFieldIdToNormalizedFieldDict =
    getExternalFieldIdToNormalizedFieldDict(
      documents,
      canUseNormalizedFieldsAsKeys,
    );

  questionSubmissions.forEach((questionSubmission) => {
    const questionVersion = getQuestionVersionFromQuestionSubmission(
      questionnaireSubmission.questionnaire_version,
      questionSubmission,
    );

    const questionSubmissionType: QuestionSubmissionType =
      questionSubmission.question_submission_type;

    if (questionVersion !== undefined) {
      switch (questionSubmissionType) {
        case 'QuestionSubmissionShort':
        case 'QuestionSubmissionLong':
        case 'QuestionSubmissionDate': {
          const response = getQuestionSubmissionResponse(questionSubmission);
          questionVersion.question_mapping_versions.forEach((mapping) => {
            const normalizedField =
              externalFieldIdToNormalizedFieldDict[
                mapping.external_field_identifier
              ];

            if (normalizedField) {
              if (questionSubmissionType == 'QuestionSubmissionDate') {
                responseDict[normalizedField] = response
                  ? moment.utc(response).format(dateFormat)
                  : '';
              } else {
                responseDict[normalizedField] = response ?? '';
              }
            }
          });
          break;
        }

        case 'QuestionSubmissionMultipleChoiceSingle': {
          const responseCollection: string[] = [];

          const selectedOptions = getQuestionSubmissionSelectedOptions(
            questionVersion,
            questionSubmission,
          );

          const questionMapping =
            questionVersion.question_mapping_versions.length > 0
              ? questionVersion.question_mapping_versions[0]
              : undefined;
          if (questionMapping === undefined) {
            break;
          }
          // tagStr is {normalized_entity}.{normalized_attribute}
          const tagStr = questionMapping.external_field_identifier;
          const lawyawOptions = attributeTagToOptionsMap[tagStr];

          if (lawyawOptions !== undefined && lawyawOptions.length > 0) {
            // a single-select question maps a single-select field
            lawyawOptions.forEach((lawyawOption: any) => {
              const selectedOption = selectedOptions.find((selectedOption) =>
                selectedOption.question_option_mapping_versions.some(
                  (optionMapping) =>
                    parseInt(optionMapping.external_field_identifier) ===
                    lawyawOption.id,
                ),
              );
              if (selectedOption !== undefined) {
                responseCollection.push(lawyawOption.value);
              }
            });
          } else {
            // a single-select question maps a text field
            if (selectedOptions && selectedOptions[0]) {
              responseCollection.push(selectedOptions[0].label);
            }
          }

          const normalizedField =
            externalFieldIdToNormalizedFieldDict[
              questionMapping.external_field_identifier
            ];

          if (normalizedField === undefined) {
            break;
          }

          responseDict[normalizedField] = responseCollection[0] ?? '';
          break;
        }

        case 'QuestionSubmissionMultipleChoiceMulti': {
          let responseCollection: string[] = [];

          const selectedOptions = getQuestionSubmissionSelectedOptions(
            questionVersion,
            questionSubmission,
          );

          const questionMapping =
            questionVersion.question_mapping_versions.length > 0
              ? questionVersion.question_mapping_versions[0]
              : undefined;
          if (questionMapping === undefined) {
            break;
          }
          // tagStr is {normalized_entity}.{normalized_attribute}
          const tagStr = questionMapping.external_field_identifier;
          const lawyawOptions = attributeTagToOptionsMap[tagStr];

          if (lawyawOptions !== undefined && lawyawOptions.length > 0) {
            // a multi-select question maps a multi-select field
            lawyawOptions.forEach((lawyawOption: any) => {
              const selectedOption = selectedOptions.find((selectedOption) =>
                selectedOption.question_option_mapping_versions.some(
                  (optionMapping) =>
                    parseInt(optionMapping.external_field_identifier) ===
                    lawyawOption.id,
                ),
              );
              if (selectedOption !== undefined) {
                responseCollection.push(lawyawOption.value);
              } else {
                // need to include empty strings for each option that was not selected specifically
                // for multiselect.  This is how Lawyaw's populate page works.
                responseCollection.push('');
              }
            });
          } else {
            // a multi-select question maps a text field
            responseCollection = selectedOptions.map(
              (selectedOptions) => selectedOptions.label,
            );
          }

          const normalizedField =
            externalFieldIdToNormalizedFieldDict[
              questionMapping.external_field_identifier
            ];

          if (normalizedField === undefined) {
            break;
          }

          responseDict[normalizedField] = responseCollection.join(', ');
          break;
        }
      }
    }
  });

  return responseDict;
};

export const getFieldId2ResponseMap = (
  questionnaireSubmission: QuestionnaireSubmission,
  documents: Document[],
) => {
  const responseDict: { [key: string]: string } = {};

  const questionSubmissions =
    questionnaireSubmission.section_submissions_attributes.flatMap(
      (sectionSubmission) => sectionSubmission.question_submissions_attributes,
    );

  const attributeTagToOptionsMap = getAttributeTagToOptionsMap(documents);

  questionSubmissions.forEach((questionSubmission) => {
    const questionVersion = getQuestionVersionFromQuestionSubmission(
      questionnaireSubmission.questionnaire_version,
      questionSubmission,
    );

    const questionSubmissionType: QuestionSubmissionType =
      questionSubmission.question_submission_type;

    if (questionVersion !== undefined) {
      switch (questionSubmissionType) {
        case 'QuestionSubmissionShort':
        case 'QuestionSubmissionLong':
        case 'QuestionSubmissionDate': {
          const response = getQuestionSubmissionResponse(questionSubmission);
          questionVersion.question_mapping_versions.forEach((mapping) => {
            if (questionSubmissionType == 'QuestionSubmissionDate') {
              responseDict[mapping.external_field_identifier] = response
                ? moment.utc(response).format(dateFormat)
                : '';
            } else {
              responseDict[mapping.external_field_identifier] = response ?? '';
            }
          });
          break;
        }

        case 'QuestionSubmissionMultipleChoiceSingle': {
          const responseCollection: string[] = [];

          const selectedOptions = getQuestionSubmissionSelectedOptions(
            questionVersion,
            questionSubmission,
          );

          const questionMapping =
            questionVersion.question_mapping_versions.length > 0
              ? questionVersion.question_mapping_versions[0]
              : undefined;
          if (questionMapping === undefined) {
            break;
          }
          // tagStr is {Entity.id}.{Attribute.id} or {normalized_entity}.{normalized_attribute}
          const tagStr = questionMapping.external_field_identifier;
          const lawyawOptions = attributeTagToOptionsMap[tagStr];

          if (lawyawOptions !== undefined && lawyawOptions.length > 0) {
            // a single-select question maps a single-select field
            lawyawOptions.forEach((lawyawOption: any) => {
              const selectedOption = selectedOptions.find((selectedOption) =>
                selectedOption.question_option_mapping_versions.some(
                  (optionMapping) =>
                    optionMapping.external_field_identifier ===
                    lawyawOption.value,
                ),
              );
              if (selectedOption !== undefined) {
                responseCollection.push(lawyawOption.value);
              }
            });
          } else {
            // a single-select question maps a text field
            if (selectedOptions && selectedOptions[0]) {
              responseCollection.push(selectedOptions[0].label);
            }
          }

          responseDict[questionMapping.external_field_identifier] =
            responseCollection[0] ?? '';
          break;
        }

        case 'QuestionSubmissionMultipleChoiceMulti': {
          let responseCollection: string[] = [];

          const selectedOptions = getQuestionSubmissionSelectedOptions(
            questionVersion,
            questionSubmission,
          );

          const questionMapping =
            questionVersion.question_mapping_versions.length > 0
              ? questionVersion.question_mapping_versions[0]
              : undefined;
          if (questionMapping === undefined) {
            break;
          }
          // tagStr is {Entity.id}.{Attribute.id} or {normalized_entity}.{normalized_attribute}
          const tagStr = questionMapping.external_field_identifier;
          const lawyawOptions = attributeTagToOptionsMap[tagStr];

          if (lawyawOptions !== undefined && lawyawOptions.length > 0) {
            // a multi-select question maps a multi-select field
            lawyawOptions.forEach((lawyawOption: any) => {
              const selectedOption = selectedOptions.find((selectedOption) =>
                selectedOption.question_option_mapping_versions.some(
                  (optionMapping) =>
                    optionMapping.external_field_identifier ===
                      lawyawOption.value ||
                    parseInt(optionMapping.external_field_identifier) ===
                      lawyawOption.id,
                ),
              );
              if (selectedOption !== undefined) {
                responseCollection.push(lawyawOption.value);
              } else {
                // need to include empty strings for each option that was not selected specifically
                // for multiselect.  This is how Lawyaw's populate page works.
                responseCollection.push('');
              }
            });

            // stacked_save_data format is ["soccer","hockey","basketball","",""]
            responseDict[questionMapping.external_field_identifier] =
              JSON.stringify(responseCollection);
            break;
          } else {
            // a multi-select question maps a text field
            responseCollection = selectedOptions.map(
              (selectedOptions) => selectedOptions.label,
            );
            responseDict[questionMapping.external_field_identifier] =
              responseCollection.join(', ');
            break;
          }
        }

        case 'QuestionSubmissionCheckbox': {
          questionVersion.question_option_versions.forEach((optionVersion) => {
            optionVersion.question_option_mapping_versions.forEach(
              (mapping) => {
                const isFound =
                  questionSubmission.question_submission_selected_options.find(
                    (so) => optionVersion.id === so.question_option_version_id,
                  );
                if (isFound) {
                  responseDict[mapping.external_field_identifier] = 'Yes';
                }
              },
            );
          });
        }
      }
    }
  });

  return responseDict;
};

export const tryConvertToNumber = (str: string): number | string => {
  const num = Number(str);

  return isNaN(num) ? str : num;
};

export const createPrepopulateRecord = (
  v2Data: Record<string, string>,
  questionnaireSubmission: QuestionnaireSubmission,
) => {
  let externalFieldIdentifierVersionHash =
    createExternalFieldIdentifierVersionHash(questionnaireSubmission);
  let questionOptionVersionIdHash = createQuestionOptionVersionIdHash(
    questionnaireSubmission,
  );

  let updatedSectionSubmissionsAttributes: {
    section_version_id: number;
    question_submissions_attributes: {
      question_version_id: number;
      response_date?: string;
      response_text?: string;
      question_submission_selected_options_attributes?: {
        question_option_version_id: number;
      }[];
    }[];
  }[] = [];

  for (let [key, value] of Object.entries(v2Data)) {
    if (!(key in externalFieldIdentifierVersionHash)) continue;

    let questionVersion = externalFieldIdentifierVersionHash[key];

    if (!questionVersion || !value || value.trim() === '') continue;

    let questionType = questionVersion.question_type;

    if (
      !updatedSectionSubmissionsAttributes.some(
        (section) =>
          section['section_version_id'] === questionVersion?.section_version_id,
      )
    ) {
      updatedSectionSubmissionsAttributes.push({
        section_version_id: questionVersion.section_version_id,
        question_submissions_attributes: [],
      });
    }

    updatedSectionSubmissionsAttributes.forEach((section) => {
      if (section['section_version_id'] !== questionVersion?.section_version_id)
        return;

      let response: {
        response_date?: string;
        response_text?: string;
        question_submission_selected_options_attributes?: {
          question_option_version_id: number;
        }[];
      } = {};

      switch (questionType) {
        case 'QuestionDate': {
          // questionnaire-service uses ISO 8601 format for dates
          const formattedDate = moment(value).toISOString();
          response['response_date'] = formattedDate;
          break;
        }
        case 'QuestionMultipleChoiceSingle': {
          let questionVersionId = questionVersion.question_version_id;
          let multipleChoiceVersion =
            questionOptionVersionIdHash[questionVersionId];

          if (!multipleChoiceVersion || !questionOptionVersionIdHash) return;

          const questionOptionVersionId = multipleChoiceVersion[value]?.id;

          if (!questionOptionVersionId) return;

          response['question_submission_selected_options_attributes'] = [
            {
              question_option_version_id: questionOptionVersionId,
            },
          ];
          break;
        }
        case 'QuestionMultipleChoiceMulti': {
          let questionVersionId = questionVersion.question_version_id;
          let multipleChoiceVersion =
            questionOptionVersionIdHash[questionVersionId];

          if (!multipleChoiceVersion || !questionOptionVersionIdHash) return;

          const item = multipleChoiceVersion;

          let result = convertToArrayIfPossible(value)
            .map((option) => {
              const questionOptionVersionId = item[option]?.id;
              if (!questionOptionVersionId) return null;

              return {
                question_option_version_id: questionOptionVersionId,
              };
            })
            .filter((option) => option !== null);

          if (!result || result.length === 0) return;

          response['question_submission_selected_options_attributes'] =
            result as {
              question_option_version_id: number;
            }[];

          break;
        }
        default: {
          response['response_text'] = value;
          break;
        }
      }

      section['question_submissions_attributes'].push({
        question_version_id: questionVersion?.question_version_id,
        ...response,
      });
    });
  }

  return updatedSectionSubmissionsAttributes;
};

export const createExternalFieldIdentifierVersionHash = (
  questionnaireSubmission: QuestionnaireSubmission,
) => {
  let externalFieldIdentifierVersionHash: Record<
    string,
    {
      question_version_id: number;
      section_version_id: number;
      question_type: string;
    }
  > = {};

  questionnaireSubmission.questionnaire_version.section_versions.forEach(
    (sectionVersion: SectionVersion) => {
      sectionVersion.question_versions.forEach(
        (questionVersion: QuestionVersion) => {
          questionVersion.question_mapping_versions.forEach(
            (questionMappingVersion) => {
              externalFieldIdentifierVersionHash[
                questionMappingVersion.external_field_identifier
              ] = {
                question_version_id: questionVersion.id,
                section_version_id: sectionVersion.id,
                question_type: questionVersion.question_type,
              };
            },
          );
        },
      );
    },
  );

  return externalFieldIdentifierVersionHash;
};

export const createQuestionOptionVersionIdHash = (
  questionnaireSubmission: QuestionnaireSubmission,
) => {
  let questionOptionVersionIdHash: Record<
    number,
    Record<string, { id: number }>
  > = {};

  questionnaireSubmission.questionnaire_version.section_versions.forEach(
    (sectionVersion: SectionVersion) => {
      sectionVersion.question_versions.forEach((questionVersion) => {
        let questionType = questionVersion.question_type;
        if (
          ![
            'QuestionMultipleChoiceSingle',
            'QuestionMultipleChoiceMulti',
          ].includes(questionType)
        )
          return;

        let externalFieldIdentifierMultipleChoiceVersionHash: Record<
          string,
          { id: number }
        > = {};
        questionVersion.question_option_versions.forEach(
          (questionOptionVersion) => {
            externalFieldIdentifierMultipleChoiceVersionHash[
              questionOptionVersion.label
            ] = {
              id: questionOptionVersion.id,
            };

            const key = questionVersion.id;

            if (!key) return;

            questionOptionVersionIdHash[key] =
              externalFieldIdentifierMultipleChoiceVersionHash;
          },
        );
      });
    },
  );

  return questionOptionVersionIdHash;
};
