import React from 'react';

type SummaryFormItemProps = {
  label: React.ReactNode;
  price: React.ReactNode;
};

export const SummaryFormItem = ({ label, price }: SummaryFormItemProps) => {
  return (
    <div className="flex flex-row justify-between text-xs">
      <span>{label}</span>
      <span className="whitespace-nowrap">{price}</span>
    </div>
  );
};
