import React from 'react';
import { BundleProduct } from '~/src/entities/subscription/types';
import { SummaryFormItem } from './SummaryFormItem';
import { planPrice } from './utils';

const BundlePlanItem = ({ bundle }: { bundle: BundleProduct }) => {
  const name = `${bundle.productLabel} (${bundle.courtFormProduct} and ${bundle.docAutoProduct})`;
  return (
    <SummaryFormItem
      label={name}
      price={planPrice(bundle.price / 100, bundle.billingCycle)}
    />
  );
};

export default BundlePlanItem;
