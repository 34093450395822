const untaggedFieldRegex = new RegExp('^untagged_\\d+$');

/**
 * Returns a list excluding the untagged fields.
 * @param originalCardList original card list that might include untagged fields
 */
export const getCardListExcludingUntaggedFields = (originalCardList) => {
  const cardListExcludingUntaggedFields = {};

  for (let key in originalCardList) {
    let isTaggedField = untaggedFieldRegex.test(key);
    if (!isTaggedField) {
      cardListExcludingUntaggedFields[key] = originalCardList[key];
    }
  }

  return cardListExcludingUntaggedFields;
};
